<template>
    <v-layout></v-layout>
</template>

<script type="plain/text">
import DocService from "@/scripts/services/doc.service";
    export default {
        components: {
            DocService
        },
        name: "IncomeReport",
        data() {
            return {
                docService: new DocService()
            }
        },
        created(){
            let chave = this.$route.query.key;
            this.docService.getIncome(chave, this.callbackIncomeReport);
        },
        methods: {
            callbackIncomeReport(response){
                 let blob = new Blob([response.data],{ type:'application/pdf'});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "InformeDoacoes.pdf";
                link.click();
            }
        }
    }
</script>

<style scoped>

</style>